@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap";

.section-common {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.section-common__2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section-common__double {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.hero-container {
  position: relative;
  background-color: #360a6b;
  padding: 3rem 0;
  overflow: hidden;
  display: table;

  &::before {
    content: "";
    position: absolute;
    width: 65%;
    height: 140%;
    top: -22%;
    right: 0%;
    background: radial-gradient(
      closest-side at 100% 100%,
      rgb(191 95 236) 0%,
      rgba(54, 10, 107, 0) 50%
    );
    display: block;
    z-index: 0;
    border-radius: 50%;
  }
}

.hero_title {
  color: #ffffff;
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 1.5rem 0;
  font-family: "Poppins", sans-serif;

  span {
    display: block;
  }

  .hero_title-sec-2 {
    font-size: 70px;
  }
}

.hero-para {
  margin-bottom: 1rem;

  p {
    margin: 0;
    line-height: 1.5;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
  }
}

.hero-hash {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
}

.hero_top_header {
  font-size: 24px;
  font-weight: 700;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#137ffc),
    color-stop(50%, #f94f5a),
    to(#137ffc)
  );
  background-image: linear-gradient(90deg, #137ffc 0%, #f94f5a 50%, #137ffc);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  row-gap: 70px;
}

p {
  color: #23272a;
  font-size: 20px;
  line-height: 1.625;
}

.hero_logo img {
  max-width: 260px;
  width: 100%;
}

.primary_button {
  z-index: 1;
  color: #000;
  height: 65px;
  width: 225px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  font-weight: 700;
  overflow: hidden;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  justify-content: flex-start;
  -webkit-box-shadow: 0 5px 16px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 5px 16px rgb(0 0 0 / 10%);
  text-decoration: none;
  padding: 1rem;
  font-size: 18px;
}

.primary_button.not-available {
  cursor: default;
  box-shadow: none;
  background-color: #dbdbdb;
  pointer-events: none;
}

.primary_button.not-available:before {
  background-image: none;
}

.primary_button.not-available span {
}

.primary_button.not-available:hover div {
  color: #000;
}

.primary_button .p_btn_inner {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
}

.primary_button .p_btn_inner img {
  width: 32px;
}

.primary_button:hover {
  color: #fff;
}

.primary_button:hover span {
  color: #fff;
}

.primary_button:before {
  top: 0;
  right: 0;
  left: auto;
  content: "";
  z-index: -1;
  width: 0%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background-image: linear-gradient(40deg, #870085 0%, #2d002c 100%);
}

.primary_button:hover:before {
  left: 0;
  width: 100%;
  right: auto;
}

.hero_action_buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  .primary_button {
    background-color: #000000;
  }
}

.primary_button span {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  color: #ffffff;
  display: block;
}

.hero_image {
  max-width: 600px;
  padding-left: 2rem;
  position: relative;
  bottom: -52px;
}

.hero_image img {
  max-width: 100%;
  height: auto;
}

.nav-header .icon {
  display: none;
}

.hero_logo_mobile {
  display: none;
}

.poruwa-section {
  text-align: center;
  background-color: #f1f2f7;
  color: #ffffff;
}

.poruwa-section .about-poruwa-main {
  color: #4e4e4e;
  font-size: 1.2rem;
}

.poruwa-wrapper a {
  display: block;
}

.poruwa-wrapper img {
  margin: 0;
  max-height: 2.5rem;
  width: auto;
}

.about-poruwa {
  display: block;
  color: #f2f2f2;
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
  max-width: 600px;
  margin: 1rem auto 3rem;
}

footer {
  background-color: #252525;
}

.h-btn {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1.7;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  background-color: #ffffff;
  color: #222222;
  letter-spacing: 1px;
  padding: 12px 24px;
  font-weight: 500;
}

.h-btn:hover {
  color: #70006e;
  background-color: #c5c5c5;
}

.privacy-and-conditions {
  display: flex;
  justify-content: center;
  gap: 1.5rem;

  .divider {
    width: 1px;
    background-color: #ffffff;
    display: block;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.supportive-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
  }
}

.copyright {
  text-align: center;
  padding: 1rem;
  background-color: #2c2c2c;
}

.copyright p {
  font-size: 13px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin: 0;
}

.copyright p a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.copyright p a:hover {
  text-decoration: underline;
}

.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home-container > footer {
  margin-top: auto;
}

.register-container {
  text-align: center;
}
